<template>
  <SwapDefaultPage
    :names="activeMember.name || 'Medsökande'"
    :possessions="memberList"
    v-on="$listeners"
    @possessionClick="setSelectedPossessionIndex"
  >
    <template slot="page-possession-addition">
      <BaseButton
        v-if="showRemoveMember || activeApplicantMembers.length === 1"
        :is-transparent="true"
        :is-loading="false"
        :is-secondary="true"
        class="toggle-members"
        :disabled="applicationIsSent || applicationIsArchived"
        @click="handleMemberToggleClick(activeApplicantMembers.length === 1)"
      >
        <BaseIconText
          v-if="showRemoveMember"
          :icon-url="svgs.ICONS.REMOVE_CIRCLE_BLUE"
          >Ta bort boende
        </BaseIconText>
        <BaseIconText
          v-else-if="activeApplicantMembers.length === 1"
          :icon-url="svgs.ICONS.ADD_CIRCLE_BLUE"
          >Lägg till boende
        </BaseIconText>
      </BaseButton>
      <InfoButtonModal title="Vad är detta?"
        ><p>
          Står det mer än en person på hyreskontraktet behöver du också fylla i
          information om den medsökande parten.
        </p></InfoButtonModal
      >
    </template>
    <RejectionMessageCard category="MEMBERS" />
    <transition name="form" mode="out-in">
      <SwapForm
        :key="activeMember.id"
        :is-loading="savingApplicationData"
        :form-has-changed="formHasChanged"
        v-on="$listeners"
      >
        <Fieldset>
          <SwapInput
            v-model="activeMember.name"
            :disabled="savingApplicationData"
            label="Fullständigt namn"
            placeholder="Fullständigt namn"
            description="Ange det för- och efternamn som ska stå på kontraktet"
            autocomplete="given-name"
            :validation="validateFullName"
            error-text="Fullständigt namn måste anges på detta vis: Sven Svensson"
          />
          <SwapInput
            v-model="activeMember.email"
            :disabled="savingApplicationData"
            label="E-post"
            placeholder="E-post"
            :description="`Ange ${formMemberName('din')} e-postadress`"
            type="email"
            :validation="validEmail"
            error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
            autocomplete="email"
          />
          <SwapInput
            v-model="activeMember.phone"
            :disabled="savingApplicationData"
            label="Telefonnummer"
            placeholder="Telefonnummer"
            :description="`Ange ${formMemberName('ditt')} telefonnummer`"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
            autocomplete="tel"
          />
          <SwapInput
            v-model="activeMember.ssn"
            :disabled="savingApplicationData"
            label="Personnummer"
            placeholder="Personnummer"
            :description="`Ange ${formMemberName('ditt')} personnummer`"
            :error-text="ssnErrorText"
            :validation="validateSsn"
          />
        </Fieldset>
        <SwapInput
          v-model="activeMember.optAddress"
          :disabled="savingApplicationData"
          label="Adress"
          placeholder="Adress"
          :description="`Ange ${formMemberName('din')} folkbokföringsadress`"
          autocomplete="street-address"
        />
        <Fieldset>
          <PostalCodeSearchBar
            v-model="activeMember.optPostalCode"
            :class="{ autocomplete: postalCodeAutoCompleted }"
            label="Postnummer"
            placeholder="Postnummer"
            :description="`Ange postnumret till ${formMemberName(
              'din'
            )} folkbokföringsadress`"
            autocomplete="postal-code"
            :validation="validatePostalCode"
            error-text="Postnummer måste anges med 5 siffror"
            :disabled="savingApplicationData"
            @input="postalCodeAutoCompleted = false"
            @dropdownMatch="postalCodeDropdownMatch"
          />
          <SwapInput
            v-model="activeMember.optPostalAddress"
            :disabled="savingApplicationData"
            label="Ort"
            placeholder="Ort"
            :description="`Ange orten till ${formMemberName(
              'din'
            )} folkbokföringsadress`"
            :class="{ autocomplete: postalCodeAutoCompleted }"
            autocomplete="address-level2"
            @input="postalCodeAutoCompleted = false"
          />
        </Fieldset>
        <Checkbox
          :is-disabled="applicationIsSent"
          name="hasOtherApartment"
          :description="`Denna person har ett annat fastighetsinnehav, t.ex. en bostadsrätt, hyresrätt, fritidshus eller övrig fastighet utöver Lägenhet ${getAddressDescription(
            activeApplicant,
            selectedApplicantIndex,
            selectedPossessionIndex
          )}`"
          :checked="otherPropertyOwnershipDescriptionVisible"
          @change="showOtherPropertyOwnershipDescription"
        />
        <transition
          mode="out-in"
          name="accordion-fade"
          @after-enter="afterShowOtherPropertyEnter"
        >
          <SwapInput
            v-if="otherPropertyOwnershipDescriptionVisible"
            v-model="activeMember.otherPropertyOwnershipDescription"
            label="Fastighetsinnehav"
            placeholder="Fastighetsinnehav"
            :disabled="savingApplicationData"
            type="textarea"
            description="Typ av fastighetsinnehav"
          />
        </transition>
        <Checkbox
          :is-disabled="applicationIsSent"
          :checked="activeMember.contracted || false"
          name="contracted"
          :description="`Denna person står på hyreskontraktet för Lägenhet ${getAddressDescription(
            activeApplicant,
            selectedApplicantIndex,
            selectedPossessionIndex
          )}`"
          @change="activeMember.contracted = !activeMember.contracted"
        />
      </SwapForm>
    </transition>
  </SwapDefaultPage>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import {
  validEmail,
  validatePersonalNumber,
  validatePostalCode,
  validateNumber,
  validateFullName
} from '@/utils/input-validations';
import { alphabet as _alphabet, getAddressDescription } from '@/utils/common';
import { getGenitiveNameFallback } from '@/utils/genitive';
import svgs from '@/utils/icon-constants';

import Checkbox from '@/components/Checkbox.vue';
import Fieldset from '@/components/Fieldset.vue';
import InfoButtonModal from '@/components/InfoButtonModal.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';
import SwapDefaultPage from '@/components/SwapDefaultPage.vue';
import SwapForm from '@/components/SwapForm.vue';
import SwapInput from '@/components/SwapInput.vue';
import PostalCodeSearchBar from '@/components/PostalCodeSearchBar.vue';
import RejectionMessageCard from '@/components/RejectionMessageCard.vue';

export default {
  name: 'Members',
  components: {
    Checkbox,
    Fieldset,
    BaseIconText,
    BaseButton,
    InfoButtonModal,
    SwapDefaultPage,
    SwapForm,
    SwapInput,
    PostalCodeSearchBar,
    RejectionMessageCard
  },
  props: {
    activeApplicant: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      svgs,
      otherPropertyOwnershipDescriptionVisible: false,
      ssnErrorText:
        'Personnummer måste anges med 10 siffror, t.ex. YYMMDD-XXXX',
      postalCodeAutoCompleted: false,
      validatePostalCode,
      validateNumber,
      validEmail,
      getAddressDescription,
      alphabet: _alphabet,
      validateFullName
    };
  },
  computed: {
    ...mapGetters({
      applicationIsSent: 'application/applicationIsSent',
      applicationIsArchived: 'application/applicationIsArchived'
    }),
    ...mapState({
      application: state => state.application.application,
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    newMembersRejections() {
      return !!this.activeApplicant.newRejections.members[
        this.selectedPossessionIndex
      ];
    },
    showMembersRejection() {
      return this.newMembersRejections && this.activeMember?.review?.message;
    },
    activeApplicantMembers: {
      get() {
        return this.activeApplicant.members;
      },
      set() {
        this.$emit();
      }
    },
    activeMember() {
      if (!this.activeApplicantMembers) {
        return null;
      }
      return this.activeApplicantMembers[this.selectedPossessionIndex];
    },
    memberList() {
      if (!this.activeApplicantMembers) {
        return [];
      }
      return this.activeApplicantMembers.map((member, index) => ({
        title: member.name || 'Medsökande',
        active: this.selectedPossessionIndex === index,
        tabLink: index,
        classes: !member.name ? ['default-value'] : undefined
      }));
    },
    nextApplicationApartmentsAmount() {
      if (!this.applicants) {
        return null;
      }
      let nextIndex = this.selectedApplicantIndex + 1;
      if (nextIndex > this.applicants.length - 1) {
        nextIndex = 0;
      }
      return this.applicants[nextIndex].apartments.length;
    },
    showRemoveMember() {
      if (
        this.activeApplicant.apartments.length > 1 ||
        this.nextApplicationApartmentsAmount > 1
      ) {
        return false;
      }

      if (this.activeApplicantMembers.length > 1) {
        return true;
      }
      return false;
    }
  },
  watch: {
    activeMember: {
      handler(nextMember, previousMember) {
        if (nextMember !== previousMember) {
          this.postalCodeAutoCompleted = false;
        }
        if (this.hasOtherPropertyOwnershipDescription(nextMember)) {
          this.showOtherPropertyOwnershipDescription(true);
        } else {
          this.showOtherPropertyOwnershipDescription(false);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),
    formMemberName(defaultWord) {
      return this.selectedApplicantIndex === 0
        ? defaultWord
        : getGenitiveNameFallback(this.activeMember.name);
    },
    postalCodeDropdownMatch(match) {
      this.activeMember.optPostalCode = match.name;
      this.activeMember.optPostalAddress = match.description;
      this.postalCodeAutoCompleted = true;
    },
    hasOtherPropertyOwnershipDescription(member) {
      if (!member) {
        return false;
      }

      const otherPropertyOwnershipDescription =
        member.otherPropertyOwnershipDescription;
      if (
        otherPropertyOwnershipDescription &&
        otherPropertyOwnershipDescription.length > 0
      ) {
        return true;
      }
      return false;
    },

    validateSsn(value) {
      if (!validatePersonalNumber(value)) {
        this.ssnErrorText = 'Det personnummer du angivit är inte giltigt';
        return false;
      }
      return validatePersonalNumber(value);
    },
    afterShowOtherPropertyEnter() {
      window.scrollTo({
        top: document.body.clientHeight,
        behavior: 'smooth'
      });
    },
    showOtherPropertyOwnershipDescription(show) {
      this.otherPropertyOwnershipDescriptionVisible = show;
    },
    handleMemberToggleClick(add) {
      this.$emit('isEditing', true);
      if (add) {
        this.$set(this.activeApplicantMembers, 1, {
          id: Math.floor(Math.random() * 100000),
          name: '',
          email: '',
          phone: '',
          ssn: '',
          optAddress: '',
          optPostalCode: '',
          optPostalAddress: '',
          otherPropertyOwnershipDescription: '',
          contracted: false
        });
        this.setSelectedPossessionIndex(1);
      } else {
        this.setSelectedPossessionIndex(0);
        this.$delete(this.activeApplicantMembers, 1);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.toggle-members {
  text-decoration: none;
  color: var(--color-blue);
  padding: 1.2rem 1.4rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;
  img {
    width: 20px;
    height: 20px;
    margin: 5px;
  }
}

.default-value {
  color: #666;
}

.autocomplete :deep(input) {
  background-color: #e8f0fe;
}
</style>
