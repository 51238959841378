import wasCreatedBeforeSplitRelease from '@/utils/wasCreatedBeforeSplitRelease';
import {
  validatePostalCode,
  validateNumber,
  validEmail,
  validatePersonalNumber,
  fieldIsEmpty,
  haveSameSwapAddressSelected,
  validateFullName
} from '@/utils/input-validations';
import {
  getAddressDescription,
  getActiveMemberSwapAddresses,
  hasNewPropertyRejections
} from '@/utils/common';

const summarizeStatuses = statuses => {
  if (!statuses || statuses.every(status => status === '')) return '';
  if (statuses.every(status => status === 'EMPTY')) return 'EMPTY';
  if (statuses.includes('COMMENT')) return 'COMMENT';
  if (statuses.every(status => status === 'APPROVED')) return 'APPROVED';
  if (statuses.includes('INVALID')) return 'INVALID';
  if (statuses.some(status => ['INCOMPLETE', 'EMPTY'].includes(status)))
    return 'INCOMPLETE';
  return 'COMPLETE';
};

function selectedSwapAddress(applicants, applicantIndex, apartmentIndex) {
  const swapAddresses = getActiveMemberSwapAddresses(
    applicants,
    applicantIndex
  );
  return swapAddresses[apartmentIndex];
}

function useSplitDocument(application, applicant, category) {
  // Older application using system before release of document split
  if (wasCreatedBeforeSplitRelease(application)) {
    return false;
  }

  // Members sharing apartment only need to upload invoice/agreement once and
  // is therefore a "shared category"
  if (
    ['LEASE_AGREEMENT', 'RENTAL_INVOICE'].includes(category) &&
    applicant.members.length > applicant.apartments.length
  ) {
    return false;
  }

  return true;
}

function getDocumentGroups(application, applicant, category) {
  if (!useSplitDocument(application, applicant, category)) {
    return [applicant.documentGroups[0]];
  } else if (applicant.members.length === 1) {
    return [applicant.documentGroups[0]];
  }
  return applicant.documentGroups;
}

export default {
  applicationStatus(state, getters, rootState) {
    const application = rootState.application.application;
    if (!application) return 'INCOMPLETE';

    return application.applicants.every(
      applicant =>
        applicant.apartments &&
        applicant.members &&
        applicant.members.every(member => member.name)
    )
      ? 'COMPLETE'
      : 'INCOMPLETE';
  },
  all(state, getters, rootState) {
    const application = rootState.application.application;
    if (!application) return null;

    return application.applicants.map((applicant, applicantIndex) => {
      return {
        APARTMENTS: applicant.apartments.map(apartment => ({
          address: {
            label: 'Adress',
            value: apartment.address,
            valid: true
          },
          postalCode: {
            label: 'Postnummer',
            value: apartment.postalCode,
            valid: validatePostalCode(apartment.postalCode)
          },
          postalAddress: {
            label: 'Ort',
            value: apartment.postalAddress,
            valid: true
          },
          objectNumber: {
            label: 'Objektnummer/kontraktsnummer',
            value: apartment.objectNumber,
            valid: true
          },
          apartmentNumber: {
            label: 'Lägenhetsnummer',
            value: apartment.apartmentNumber,
            valid: validateNumber(apartment.apartmentNumber)
          },
          rooms: {
            label: 'Antal rum',
            value: apartment.rooms,
            valid: validateNumber(apartment.rooms)
          },
          sqm: {
            label: 'Yta',
            value: apartment.sqm,
            valid: validateNumber(apartment.sqm)
          },
          rent: {
            label: 'Hyra',
            value: apartment.rent,
            valid: validateNumber(apartment.rent)
          },
          contractStartDate: {
            label: 'Innehaft sedan',
            value: apartment.contractStartDate,
            valid: true
          },
          adultCount: {
            label: 'Antal vuxna',
            value: apartment.adultCount,
            valid: validateNumber(apartment.adultCount)
          },
          childCount: {
            label: 'Antal barn',
            value: apartment.childCount,
            valid: validateNumber(apartment.childCount)
          },
          landlordName: {
            label: 'Nuvarande hyresvärd',
            value: apartment.landlordName,
            valid: true
          },
          landlordEmail: {
            label: 'Hyresvärdens telefonnummer',
            value: apartment.landlordEmail,
            valid: true
          },
          landlordPhone: {
            label: 'Hyresvärdens e-postadress',
            value: apartment.landlordPhone,
            valid: true
          }
        })),
        MEMBERS: applicant.members.map((member, index) => ({
          name: {
            label: 'Fullständigt namn',
            value: member.name,
            valid: validateFullName(member.name)
          },
          email: {
            label: 'E-post',
            value: member.email,
            valid: validEmail(member.email)
          },
          phone: {
            label: 'Telefonnummer',
            value: member.phone,
            valid: validateNumber(member.phone)
          },
          ssn: {
            label: 'Personnummer',
            value: member.ssn,
            valid: validatePersonalNumber(member.ssn)
          },
          optAddress: {
            label: 'Adress',
            value: member.optAddress,
            valid: true
          },
          optPostalCode: {
            label: 'Postnummer',
            value: member.optPostalCode,
            valid: validatePostalCode(member.optPostalCode)
          },
          optPostalAddress: {
            label: 'Ort',
            value: member.optPostalAddress,
            valid: true
          },
          otherPropertyOwnershipDescription: {
            label: 'Annat fastighetsinnehav',
            value: member.otherPropertyOwnershipDescription ? 'Ja' : 'Nej',
            valid: true
          },
          contracted: {
            label: `Står på hyreskontraktet för Lägenhet ${getAddressDescription(
              applicant,
              applicantIndex,
              index
            )}`,
            value: member.contracted ? 'Ja' : 'Nej',
            valid: true
          }
        })),
        SWAPREASON: [
          {
            description: {
              label: 'Skäl',
              value: applicant.swapReason.description,
              valid: true
            }
          }
        ],
        SWAPDETAILS: applicant.swapDetails.map((swapDetail, detailIndex) => ({
          adultCount: {
            label: 'Antal vuxna',
            value: swapDetail.adultCount,
            valid: validateNumber(swapDetail.adultCount)
          },
          childCount: {
            label: 'Antal barn',
            value: swapDetail.childCount,
            valid: validateNumber(swapDetail.childCount)
          },
          swapDate: {
            label: 'Önskat bytesdatum',
            value: swapDetail.swapDate,
            valid: true
          },
          apartmentIndex: {
            label: 'Adress:',
            value: selectedSwapAddress(
              application.applicants,
              applicantIndex,
              swapDetail.apartmentIndex
            ),
            valid: !haveSameSwapAddressSelected(
              application.applicants,
              applicantIndex,
              detailIndex
            )
          }
        })),
        CREDIT_INFORMATION: getDocumentGroups(
          application,
          applicant,
          'CREDIT_INFORMATION'
        ).map(group => group.CREDIT_INFORMATION.documents),
        FAMILY_CERTIFICATE: getDocumentGroups(
          application,
          applicant,
          'FAMILY_CERTIFICATE'
        ).map(group => group.FAMILY_CERTIFICATE.documents),
        INCOME_BASIS: getDocumentGroups(
          application,
          applicant,
          'INCOME_BASIS'
        ).map(group => group.INCOME_BASIS.documents),
        LEASE_AGREEMENT: getDocumentGroups(
          application,
          applicant,
          'LEASE_AGREEMENT'
        ).map(group => group.LEASE_AGREEMENT.documents),
        RENTAL_INVOICE: getDocumentGroups(
          application,
          applicant,
          'RENTAL_INVOICE'
        ).map(group => group.RENTAL_INVOICE.documents),
        OTHER: getDocumentGroups(application, applicant, 'OTHER').map(
          group => group.OTHER.documents
        )
      };
    });
  },
  allSummarized(state, getters) {
    if (!getters.eachApplicantSummarized) return null;

    const merged = {};

    getters.eachApplicantSummarized.forEach(applicant => {
      Object.entries(applicant).forEach(([key, value]) => {
        if (!merged[key]) merged[key] = [];
        merged[key].push(value);
      });
    });

    return {
      APPLICATION: getters.applicationStatus,
      APARTMENTS: summarizeStatuses(merged.APARTMENTS),
      MEMBERS: summarizeStatuses(merged.MEMBERS),
      SWAPREASON: summarizeStatuses(merged.SWAPREASON),
      SWAPDETAILS: summarizeStatuses(merged.SWAPDETAILS),
      CREDIT_INFORMATION: summarizeStatuses(merged.CREDIT_INFORMATION),
      FAMILY_CERTIFICATE: summarizeStatuses(merged.FAMILY_CERTIFICATE),
      INCOME_BASIS: summarizeStatuses(merged.INCOME_BASIS),
      LEASE_AGREEMENT: summarizeStatuses(merged.LEASE_AGREEMENT),
      RENTAL_INVOICE: summarizeStatuses(merged.RENTAL_INVOICE),
      OTHER: summarizeStatuses(merged.OTHER)
    };
  },
  eachApplicantSummarized(state, getters, rootState) {
    const application = rootState.application.application;
    if (!application) return [];

    const categories = [
      'APARTMENTS',
      'MEMBERS',
      'SWAPREASON',
      'SWAPDETAILS',
      'CREDIT_INFORMATION',
      'FAMILY_CERTIFICATE',
      'INCOME_BASIS',
      'LEASE_AGREEMENT',
      'RENTAL_INVOICE',
      'OTHER'
    ];

    const checkReviewStatus = (items, category, applicantIndex) => ({
      comment: items.some(
        (item, index) =>
          hasNewPropertyRejections(
            application.applicants,
            applicantIndex,
            index,
            category
          ) && item.review?.message
      ),
      approved: items.every(item => item.review?.approved)
    });

    return application.applicants.map((applicant, applicantIndex) => {
      const merged = categories.reduce((acc, category) => {
        let status = { statuses: [], approved: false, comment: false };

        switch (category) {
          case 'APARTMENTS':
            status = checkReviewStatus(
              applicant.apartments,
              category,
              applicantIndex
            );
            break;
          case 'MEMBERS':
            status = checkReviewStatus(
              applicant.members,
              category,
              applicantIndex
            );
            break;
          case 'SWAPREASON':
            status = checkReviewStatus(
              [applicant.swapReason],
              category,
              applicantIndex
            );
            break;
          case 'SWAPDETAILS':
            status = checkReviewStatus(
              applicant.swapDetails,
              category,
              applicantIndex
            );
            break;
          default:
            status = {
              comment: applicant.documentGroups.some(
                (memberDocumentGroup, index) =>
                  hasNewPropertyRejections(
                    application.applicants,
                    applicantIndex,
                    index,
                    category
                  ) &&
                  (memberDocumentGroup[category].comment ||
                    memberDocumentGroup[category].documents.some(
                      doc => doc.review?.message
                    ))
              ),
              approved: getDocumentGroups(
                application,
                applicant,
                category
              ).every(
                memberDocumentGroup => memberDocumentGroup[category].approved
              )
            };
        }

        acc[category] = {
          ...status,
          statuses: [
            summarizeStatuses(
              getters.applicantCategoryAll(applicantIndex, category)
            )
          ]
        };

        return acc;
      }, {});

      return Object.entries(merged).reduce((acc, [category, value]) => {
        let status = '';
        if (value.comment) {
          status = 'COMMENT';
        } else if (value.approved) {
          status = 'APPROVED';
        } else if (category !== 'OTHER') {
          status = summarizeStatuses(value.statuses);
        }
        return { ...acc, [category]: status };
      }, {});
    });
  },
  applicantCategoryAll: (state, getters) => (applicantIndex, category) => {
    if (!getters.all) return null;

    const documentCategories = [
      'CREDIT_INFORMATION',
      'FAMILY_CERTIFICATE',
      'INCOME_BASIS',
      'LEASE_AGREEMENT',
      'RENTAL_INVOICE',
      'OTHER'
    ];
    const data = getters.all[applicantIndex][category];
    if (!data) return [''];

    if (!documentCategories.includes(category)) {
      return data.map(category => {
        const categoryValues = Object.values(category);
        return summarizeStatuses(
          categoryValues.map(field => {
            if (fieldIsEmpty(field.value)) return 'EMPTY';
            if (!field.valid) return 'INVALID';
            return 'COMPLETE';
          })
        );
      });
    }
    return data.map(docs => {
      const statuses = docs.length ? ['COMPLETE'] : ['EMPTY'];
      return summarizeStatuses(statuses);
    });
  }
};
